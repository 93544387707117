<style lang="scss">
#debug {
  display: none;
}

#frontend {
  .styrianer-werden-all {

    .header-intro-generel-box {
      background-position: center center !important;
    }

    .intro-txt {
      @media(max-width:$width-lg) {
        margin: 40px 0;
      }
    }

    .header-slideshow-outer {
      position: relative;
      width: 100%;
      height: 700px;
      z-index: -2;

      @media(min-width: 2000px) {
        height: 800px;
      }

      @media (min-width: 3000px) {
        height: 900px;
      }

      @media (max-width: 1600px) {
        height: 600px;
      }

      @media (max-width: 1200px) {
        height: 500px;
      }

      @media (max-width: $width-lg) {
        height: 400px;
      }

      @media (max-width: $width-md) {
        height: 350px;
      }
    }

    .header-image-slideshow {
      background: no-repeat 50% 50%;
      background-size: cover;
      background-image: none;

      //$items: 2;
      // $animation-time: 10s;
      //$transition-time: 10.5s;
      //$scale: 20%;

      //$total-time: ($animation-time * $items);
      //$scale-base-1: (1 + $scale / 100%);
      position: absolute;
      width: 100%;
      height: 700px;
      overflow: hidden;

      @media(min-width: 2000px) {
        height: 800px;
      }

      @media (min-width: 3000px) {
        height: 900px;
      }

      @media (max-width: 1600px) {
        height: 600px;
      }

      @media (max-width: 1200px) {
        height: 500px;
      }

      @media (max-width: $width-lg) {
        height: 400px;
      }

      @media (max-width: $width-md) {
        height: 350px;
      }

      .slideshow-image {
        position: absolute;
        width: 100%;
        height: 100%;
        background: no-repeat 50% 50%;
        background-size: cover;
        animation-name: kenburns;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-duration: 16s;
        opacity: 1;
        transform: scale(1.2);
      }

      .slideshow-image:nth-child(1) {
        animation-name: kenburns-1;
        z-index: 3;
      }

      .slideshow-image:nth-child(2) {
        animation-name: kenburns-2;
        z-index: 2;
      }

      .slideshow-image:nth-child(3) {
        animation-name: kenburns-3;
        z-index: 1;
      }

      .slideshow-image:nth-child(4) {
        animation-name: kenburns-4;
        z-index: 0;
      }

      @keyframes kenburns-1 {
        0% {
          opacity: 1;
          transform: scale(1.2);
        }

        1.5625% {
          opacity: 1;
        }

        23.4375% {
          opacity: 1;
        }

        26.5625% {
          opacity: 0;
          transform: scale(1);
        }

        100% {
          opacity: 0;
          transform: scale(1.2);
        }

        98.4375% {
          opacity: 0;
          transform: scale(1.21176);
        }

        100% {
          opacity: 1;
        }
      }

      @-webkit-keyframes kenburns-2 {
        23.4375% {
          opacity: 1;
          transform: scale(1.2);
        }

        26.5625% {
          opacity: 1;
        }

        48.4375% {
          opacity: 1;
        }

        51.5625% {
          opacity: 0;
          transform: scale(1);
        }

        100% {
          opacity: 0;
          transform: scale(1.2);
        }
      }

      @-webkit-keyframes kenburns-3 {
        48.4375% {
          opacity: 1;
          transform: scale(1.2);
        }

        51.5625% {
          opacity: 1;
        }

        73.4375% {
          opacity: 1;
        }

        76.5625% {
          opacity: 0;
          transform: scale(1);
        }

        100% {
          opacity: 0;
          transform: scale(1.2);
        }
      }

      @-webkit-keyframes kenburns-4 {
        73.4375% {
          opacity: 1;
          transform: scale(1.2);
        }

        76.5625% {
          opacity: 1;
        }

        98.4375% {
          opacity: 1;
        }

        100% {
          opacity: 0;
          transform: scale(1);
        }
      }



    }


    .img-person-box-outer {
      position: relative;
      top: -100px;

      @media (max-width: $width-lg) {
        top: -200px;
      }

      @media (max-width: $width-md) {
        top: -150px;

      }

      .col-lg-6 {}

      .inner-box {
        height: 550px;
        width: 550px;
        position: relative;
        text-align: center;

        @media(max-width:$width-xl) {
          height: 550px;
          width: 550px;
          margin: 0 auto;
        }

        @media(max-width:$width-lg) {
          width: 100%;
          height: auto;

        }
      }

      .img-box {

        background-size: cover;
        background-position: center center;

        @media(max-width:$width-lg) {
          margin-left: auto;
          margin-right: auto;
          height: 600px;
        }

        @media(max-width:$width-sm) {
          margin-left: auto;
          margin-right: auto;
          height: 400px;
        }
      }

      .margin-left-auto {
        margin-left: auto;

        @media(max-width:$width-xl) {
          margin-left: auto;
          margin-right: auto;
        }
      }

      .txt-box {
        @media(max-width:$width-lg) {
          margin-left: auto;
          margin-right: auto;
          padding: 30px 0;
        }

        h3 {
          font-family: 'American Typewriter ITC W04 Md';
          text-transform: none;
          letter-spacing: 1px;
          font-size: 110%;
          padding: 20px;
          max-width: 80%;
          margin: 0 auto;
          line-height: 1.7;
          position: relative;
          top: 50%;
          transform: translateY(-60%);

          @media(max-width:$width-xl) {
            font-size: 100%;
          }

          @media(max-width:$width-lg) {
            top: 0;
            transform: translateY(0%);
          }

          @media(max-width:$width-md) {
            max-width: 90%;
          }
        }

        .name {
          display: block;
          width: 100%;
          position: absolute;
          bottom: 50px;
          letter-spacing: 2px;
          text-transform: uppercase;
          font-family: 'Helvetica Neue LT W05 65 Medium';
          font-size: 18px;

          @media(max-width:$width-xl) {
            bottom: 30px;
          }

          @media(max-width:$width-lg) {
            position: relative;
            bottom: 0;
            padding-top: 50px;
            font-size: 16px;
          }

          .firma {
            display: block;
            font-size: 14px;
            margin-top: 5px;
            font-family: 'Helvetica Neue LT W05 45 Light';
          }
        }
      }

    }

    .border-outer-box {
      border: 1px solid blue;
      padding: 100px 100px 80px 100px;

      position: relative;

      top: -180px;
      border: 10px solid $blue-60per;

      max-width: 80%;
      background: #fff;
      margin: 0 auto 150px auto;

      @media (max-width: $width-lg) {
        max-width: 100%;
        top: -200px;
      }

      @media (max-width: $width-md) {
        top: -100px;
        max-width: 100%;
        padding: 40px 30px;
      }

      &:after {
        content: '';
        background: #fff;
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 100px;
        top: 80px;
        border: 10px solid $blue-60per;
        z-index: -1;

        @media (max-width: $width-lg) {
          display: none;
        }
      }

      h2 {
        margin-top: 100px;
        font-family: 'American Typewriter ITC W04 Md';
        margin-bottom: 50px;
        font-size: 230%;
        text-transform: none;

        @media(max-width:992px) {
          font-size: 180%;
        }
      }

      h4 {
        margin-top: 0px;
        font-family: 'American Typewriter ITC W04 Md';
        font-family: 'Helvetica Neue LT W05 65 Medium';
        letter-spacing: 1px;

        text-transform: none;

        @media(max-width:$width-lg) {
          font-size: 140%;
        }

        @media(max-width:$width-lg) {
          font-size: 130%;
        }
      }

      strong {
        display: inline-block;
      }

      p {
        //font-family: 'Helvetica Neue LT W05 35 Thin';
        margin-bottom: 5px;

        display: inline-block;

        a {
          display: inline-block;

          &:hover {
            color: $coral;
          }
        }
      }

      .img-box {
        text-align: right;
        margin-top: 80px;
        margin-bottom: 50px;

        @media (max-width: $width-md) {
          text-align: center;
        }

        img {
          display: inline-block;
          width: 180px;
          animation: slogan-rotating 15s linear infinite;

        }

        @keyframes slogan-rotating {
          from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
          }

          to {
            -ms-transform: rotate(-360deg);
            -moz-transform: rotate(-360deg);
            -webkit-transform: rotate(-360deg);
            -o-transform: rotate(-360deg);
            transform: rotate(-360deg);
          }
        }
      }


    }
  }
}
</style>

<template>
  <SocialMediaIconBox />
  <div class="styrianer-werden-all">
    <!--<HeaderImageBoxGenerel :style="{ 'background-image': `url(${require('@/assets/img/karriere/header-img-top.jpg')})` }"  headerImageText="Styrianer:in werden" />-->
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <div class="breadcrumb"><span>{{ $t('General.karriere') }}</span></div>
              <h1 v-html="$t('styrianerIn-werden.styrianner-werden-v2')"></h1>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-8 col-md-8">
            <p class="intro-txt text-center padding-b-m">{{ $t('styrianerIn-werden.intro-txt') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="header-slideshow-outer">
      <div class="header-image-slideshow">
        <div class="slideshow-image"
          :style="{ 'background-image': `url(${require('@/assets/img/karriere/styrianner-header-img-v1.jpg')})` }"
          style="background-position:bottom center"></div>
        <div class="slideshow-image"
          :style="{ 'background-image': `url(${require('@/assets/img/karriere/styrianner-header-img-v2.jpg')})` }"></div>
        <div class="slideshow-image"
          :style="{ 'background-image': `url(${require('@/assets/img/karriere/styrianner-header-img-v1.jpg')})` }"
          style="background-position:bottom center"></div>
        <div class="slideshow-image"
          :style="{ 'background-image': `url(${require('@/assets/img/karriere/styrianner-header-img-v2.jpg')})` }"></div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="border-outer-box">
            <div class="headline-box-std">
              <div class="headline-inner">
                <div class="breadcrumb"><span>{{ $t('General.karriere') }}</span></div>
                <h1 v-html="$t('styrianerIn-werden.lebenslauf-einer-bewerbung')"> </h1>
              </div>
            </div>
            <h2>{{ $t('styrianerIn-werden.we-are-as-good') }}</h2>

            <p v-html="$t('styrianerIn-werden.interesse-txt')"></p>

            <p v-html="$t('styrianerIn-werden.erste-infos-txt')"> </p>

            <p v-html="$t('styrianerIn-werden.bewerbungsunterlagen-txt')"></p>

            <p v-html="$t('styrianerIn-werden.bewerbung-txt')"></p>

            <p v-html="$t('styrianerIn-werden.empfangsbestaetigung-txt')"></p>

            <p v-html="$t('styrianerIn-werden.sichtung-pruefung-txt')"></p>

            <p v-html="$t('styrianerIn-werden.rueckmeldung-txt')"></p>

            <p v-html="$t('styrianerIn-werden.kennenlernen-txt')"></p>

            <p v-html="$t('styrianerIn-werden.bewerbungsprozess-txt')"></p>

            <p v-html="$t('styrianerIn-werden.zusage-txt')"></p>

            <p v-html="$t('styrianerIn-werden.ende-bewerbungsprozess-txt')"></p>

            <p v-html="$t('styrianerIn-werden.weitere-unterlagen-txt')"></p>

            <p v-html="$t('styrianerIn-werden.vertragsunterzeichnung-txt')"></p>

            <p v-html="$t('styrianerIn-werden.erster-arbeitstag-txt')"></p>

            <p v-html="$t('styrianerIn-werden.pre-und-onboarding-txt')"></p>
            <div class="img-box">
              <img src="@/assets/img/ueber-uns/slogan-one-spirit-red.svg" alt="One spirit - unlimited ideas"
                class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container img-person-box-outer">

      <div class="row justify-content-center g-0">
        <div class="col-xl-6">
          <div class="inner-box img-box margin-left-auto"
            :style="{ 'background-image': `url(${require('@/assets/img/karriere/edtih-seewald.jpg')})` }"></div>
        </div>
        <div class="col-xl-6">
          <div class="inner-box txt-box bg-wine-red color-fff">
            <h3>{{ $t('styrianerIn-werden.edith-seewald-zitat') }}</h3>
            <span class="name">Edith Seewald
              <span class="firma">STYRIA IT SOLUTIONS</span>
            </span>
          </div>
        </div>
      </div>
      <div class="row justify-content-center g-0">
        <div class="col-xl-6 order-xl-2" v-inview="['move-in-from-right']">
          <div class="inner-box img-box"
            :style="{ 'background-image': `url(${require('@/assets/img/karriere/armin-novacek.jpg')})` }"
            style="background-position:left center;"></div>
        </div>
        <div class="col-xl-6" v-inview="['move-in-from-left']">
          <div class="inner-box txt-box bg-blue-60perc margin-left-auto">
            <h3>{{ $t('styrianerIn-werden.armin-novacek-zitat') }}</h3>
            <span class="name">Armin Novacek
              <span class="firma">STYRIA DIGITAL SERVICES</span>
            </span>
          </div>
        </div>
      </div>
      <div class="row justify-content-center g-0">
        <div class="col-xl-6" v-inview="['animation-delay-0-5s', 'move-in-from-left']">

          <div class="inner-box img-box margin-left-auto"
            :style="{ 'background-image': `url(${require('@/assets/img/karriere/Mocilac-Dubravko.jpg')})` }"
            style="background-position:center center;"></div>
        </div>
        <div class="col-xl-6" v-inview="['animation-delay-0-5s', 'move-in-from-right']">
          <div class="inner-box txt-box bg-mint">
            <h3>{{ $t('styrianerIn-werden.dubravko-mocilac-zitat') }}</h3>
            <span class="name">Dubravko Mocilac
              <span class="firma">DRUCK CARINTHIA</span>
            </span>
          </div>
        </div>
      </div>
    </div>



  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Wie man Styrianer:in wird | Styria Media Group',
      keywords: ['bewerbungsprozess', 'bewerbung', 'job', 'karriere', 'styrianer', 'lebenslauf'],
      description: 'Erfahren Sie wie der Bewerbungsprozess bei der Styria Media Group abläuft.',
      image: require('@/assets/img/og/karriere.png'),

    },
    en: {
      title: 'How to become an employee | Styria Media Group',
      keywords: ['application process', 'application', 'job', 'career', 'styrian', 'resume'],
      description: 'Find out how the application process works at the Styria Media Group.',
      image: require('@/assets/img/og/karriere.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
</script>
